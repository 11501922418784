div.title-group{
    display: flex;
}
/*margin será inserida no elemento pai*/
div.title-group h3{
    margin: 0 5px;
    text-align: center;
    max-width: 75%;
}

div.title-group .grow-dash{
    flex-grow: 1;
    position: relative;
}

div.title-group .grow-dash span.tachado{
    background: var(--text-color-title-group);
    height: 1px;
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 45%;
}