.my-btn.my-btn-default{
    font-size: 36px; 
    border-radius: 7px;
    white-space: nowrap;
}

/*referente a tamanho do btn*/
.my-btn.my-btn-full{
    width: 245px ;
    height: 55px ;
}
.my-btn.my-btn-half{
    width: 120px ;
    height: 55px ;
}
.my-btn.my-btn-small{
    width: 80px ;
    height: 55px ;
    font-size: 25px;
}

/*espaçamento entre icon e text do interior do btn*/
.my-btn i{
    margin-right: 10px;
}
.my-btn.my-btn.my-btn-small i{
    margin-right: unset;
}
