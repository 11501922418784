:root{
  --color-principal: #FF4A3A;
  --color-principal-complementar: #005999;
  --color-main-text-gray: #736A68;
  --text-color-title-group: #005999;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

input,select{
  font-family: 'Roboto', sans-serif;
}

ul{
  list-style-type: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-condensed{
  font-family: Roboto Condensed;
}
.flex-center-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.mt-30{
  margin-top: 30px;
}
.mt-60{
  margin-top: 60px;
}
.m-10{
  margin: 10px 0;
}
a:focus, button:focus, input:focus, select:focus{
  outline: 1px solid #00599942;
}

.soft-shadow{
  box-shadow: 0px 0px 25px 1px #4d4d4d8c;
}

.loading-container{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: #ffffffb0;
  z-index: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #005999;

  color: var(--color-principal-complementar);
  font-size: 25px;
}

.table-historic{
  width: 100%;
  margin: 10px 0;
  font-size: 12px;
  color: #736A68;
  color: var(--color-main-text-gray);
  font-weight: 700;
}

.table-historic li{
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  align-items: baseline;
}



section.my-section{
    position: absolute;
}section.my-section.no-abs{
    position: static;
    position: initial;
}
section.my-section > div{
    width: 100vw;
    display: flex;
    justify-content: center;
}
div.my-container{
    display: flex;
    flex-direction: column;
}

div.my-container > div{
    margin: 10px 0;
}
div.my-container > button{
    margin: 5px 0;
}

div.my-container.row{
    flex-direction: row;
    width: 85vw;
    justify-content: space-between;
    align-items: center;
}
div.my-container.row > button{
    margin: 0 5px;
}

.my-btn.my-btn-default{
    font-size: 36px; 
    border-radius: 7px;
    white-space: nowrap;
}

/*referente a tamanho do btn*/
.my-btn.my-btn-full{
    width: 245px ;
    height: 55px ;
}
.my-btn.my-btn-half{
    width: 120px ;
    height: 55px ;
}
.my-btn.my-btn-small{
    width: 80px ;
    height: 55px ;
    font-size: 25px;
}

/*espaçamento entre icon e text do interior do btn*/
.my-btn i{
    margin-right: 10px;
}
.my-btn.my-btn.my-btn-small i{
    margin-right: unset;
}

div.title-group{
    display: flex;
}
/*margin será inserida no elemento pai*/
div.title-group h3{
    margin: 0 5px;
    text-align: center;
    max-width: 75%;
}

div.title-group .grow-dash{
    flex-grow: 1;
    position: relative;
}

div.title-group .grow-dash span.tachado{
    background: var(--text-color-title-group);
    height: 1px;
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 45%;
}
.menu-action-map-maker .my-dialog-card{
    background: var(--color-principal);
    border-radius: 6px;
}

.menu-action-map-maker .my-dialog-card button{
    border: none;
    background: none;
}

.menu-action-map-maker a{
    text-align: center;
    color: white;
}

.menu-action-map-maker *{
    color: white;
}

.menu-action-map-maker .fa-dog, .menu-action-map-maker .fa-map{
    font-size: 50px;
}
.menu-action-map-maker .fa-tags{
    position: relative;
    left: -6px;
    top: -4px;
}
.menu-action-map-maker .fa-draw-polygon{
    position: relative;
    left: -38px;
    top: -10px;
    color: white;
    color: var(--color-principal);
    font-size: 23px;
}

.my-float-btn{
    position: absolute;
    top:85px;
    z-index: 500;
    display: flex;
    width: 100%;
}
.my-float-btn span{
    margin: 0 auto;
    display: inline-block;
    color: var(--color-principal);
    border: 2px solid var(--color-principal);
    padding: 5px;
    border-radius: 5px;
    background: #ffffffb0;
    font-weight: bold;
}


div.my-dialog{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.my-dialog-card{
    position: relative;
    background: #fff;
    max-width: 300px;
    box-shadow: 0px 0px 56px 1px #4d4d4d26;
}

div.my-dialog-header{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
div.my-dialog-content{
    margin: 10px 20px;
    font-size: 1.2rem
}
div.my-dialog-content > *{
    margin: 7px;
}
header.my-header{
    position: absolute;
    z-index: 100;
    top: 0;
}
header.my-header::after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 22%, rgba(255,255,255,0) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
header.my-header > nav{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

nav > div{
    align-self: flex-end;
}

div.nav-menu, div.icon-return{
    color: var(--color-principal-complementar);
    font-size: 30px;
    margin-right: 20px;
    margin-left: 20px;
}

.my-header a{
    color: inherit;
}  

.my-header nav{
    padding-bottom: 5px;
}  
  
.my-header .fa-sync-alt{
    opacity: 0.5;
}

div.nav-logo{
    padding-top: 5px;
}

.main-menu-app.my-dialog{
    background: #d3d3d373;
    top: 0;
    z-index: -1 ;
}

.main-menu-app .my-dialog-card{
    min-height: 60%;
    width: 90%;
    border-radius: 8px;
}

.main-menu-app.my-dialog a{
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #80808080;
}

.main-menu-app .my-dialog-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var( --color-main-text-gray);
    font-weight: bold;
}

.historic-dialog.my-dialog{
  background: #d3d3d373;
  top: 0;
}

.historic-dialog .my-dialog-card{
  min-height: 60%;
  width: 90%;
  border-radius: 8px;
}

.historic-dialog .my-dialog-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var( --color-main-text-gray);
  font-weight: bold;
}

.my-dialog-content .table-historic li a{
  padding: 0;
}

.no-align-item-center > div.my-container {
    align-items: initial !important;
    justify-content: center;
}

.my-container .sheet-description{
    width: 23vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

div.display-foto-animal{
    margin: 0 !important;
}

div.display-foto-animal .container-image-background-caroussel{
    width: 60vw;
}
div.display-foto-animal span.grow-height{
    display: block;
    padding-top: calc( 0.75 * 100%);
}
div.display-foto-animal .slider-wrapper{
    width: 60vw;
    max-width: 250px;
}
div.sheet-description{
    color: var(--color-main-text-gray);
    display: flex;
    border: solid 1px #C9BDBB;
    padding: 5px;
    box-sizing: border-box;
}

div.group-input-name{
    position: relative;
    z-index: 45;
}
div.group-input-name .btn-rand{
    color: var(--color-main-text-gray);
    position: absolute;
    bottom: 12px;
    right: 5px;
    z-index: 100;
}

div.coroussel-cadastro-animal .container-image-background-caroussel{
    width: 70vw;
}
div.coroussel-cadastro-animal span.grow-height{
    display: block;
    padding-top: calc( 0.75 * 100%);
}
div.coroussel-cadastro-animal .slider-wrapper{
    width: 70vw;
    max-width: 250px;
}

div.pag-cadastro .new-modal > div {
    max-height: 70vh;
    top: 12vh;
    margin: 0;
}

form.my-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*inputs*/
.field-input{
    margin: 10px 0 0 0;
    width: 100%;
    color: var(--color-principal-complementar);
    font-size: 16px;
}
.field-input label{
    color: inherit;
    position: relative;
    background-color: rgba(0,0,0,0.0);
    top: 15px;
    /* transition: transform 2000ms; */
    opacity: 1;
    display: block;
}
.field-input label.ativo{
    opacity: 0;
    transition: opacity 500ms;
}
.field-input input,.field-input textarea {
    color: inherit;
    border:none;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
    border-bottom: solid 1px var(--color-principal-complementar);  
    height: 25px;       
}


/*inputs*/
.field-select{
    margin: 30px 0 0 0;
    width: 100%;
    color: var(--color-principal-complementar);
    font-size: 16px;
}
.field-select select {
    color: inherit;
    border:none;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
    border-bottom: solid 1px var(--color-principal-complementar);  
    height: 20px;       
}

option{
    font-size: 12px !important;
}
div.camera-container > section{
    max-width: 280px;
    margin: 0 auto;
}

div.h-100.card-image-add{
    padding: 10px 0;
}
p.legend{
    top: 0 !important;
    bottom: unset !important;
    display: flex !important;
    justify-content: space-between !important;
    border-radius: 0 !important;
    left: unset !important;
    margin-left: unset !important;
    width: 100% !important;
    margin-top: 0 !important;
    opacity: 0.15 !important;
    z-index: 200;
}
.carousel.carousel-slider .control-arrow[type="button"] {
    top: 35px;
}
.banner-inicial{
    margin-top: 2vh;
    min-height: 25vh;
    background: white;
    border-radius: 6px;
    border: solid 2px var(--color-principal);
    position: relative;
    color: var(--color-principal);
    text-align: center;
}
.banner-inicial .fa-plus{
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 16px;
}
.banner-inicial .fa-trash-alt{
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 16px;
}
.banner-inicial .fa-camera{
    position: absolute;
    bottom: 10vh;
    left: 0;
    width: 100%;
    font-size: 32px;
}
.banner-inicial .message{
    width: 100%;
    position: absolute;
    bottom: 2vh;
    left: 0;
}
.carousel .slide {
    background: none;
}
div.new-modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #14141450;
    /* opacity: 0.15; */
    z-index: 999;
    display: flex;
    justify-content: center;
    color: #736A68;
    left: 0;
    top: 0;
    margin: 0 !important;
}
div.new-modal a{
    color: inherit;
}
div.new-modal > div{
    background-color: #fafafa;
    border-radius: 7px;
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    /* opacity: 1; */
    position: fixed;
}
div.new-modal .modal-header{
    font-size: 18px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 1px solid #C9BDBB;
    margin: 0 25px 15px;
}
div.new-modal .modal-content{
    padding-left: 25px;
    padding-right:25px; 
}
.field-input.input-check{
    display: flex;
    justify-content: space-between;
}
.field-input.input-check label{
    top: unset;
    width: auto;
    width: initial;
}
.field-input.input-check input{
    width: 25px;
    height: 25px;
}
.card-interacao{
    display: flex;
    align-items: center;
}
div.modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #1414142a;
    /* opacity: 0.15; */
    z-index: 100;
    display: flex;
    justify-content: center;
    color: #736A68;
}
div.modal a{
    color: inherit;
}
div.modal > div{
    background-color: #fafafa;
    border-radius: 7px;
    width: 90vw;
    margin-top: 7vh;
    margin-bottom: 10vh;
    /* opacity: 1; */
}
div.modal .modal-header{
    font-size: 18px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 1px solid #C9BDBB;
    margin: 0 25px 15px;
}
div.modal .modal-content{
    padding-left: 25px;
    padding-right:25px; 
}
div.qr-reader-container > section{
    max-width: 280px;
    margin: 0 auto;
}
p.text-result-qr{
    text-align: center;
}
ul.menu-modal{
    text-align: center;
    list-style: none;
    padding: 0;
    font-size: 20px;
}
ul.menu-modal li {
    margin: 15px;
}

ul.menu-modal a {
    text-decoration: none;
}
.no-align-item-center > div.my-container {
    align-items: initial !important;
    justify-content: center;
}

.my-container .sheet-description{
    width: 23vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

div.display-foto-animal{
    margin: 0 !important;
}

div.display-foto-animal .container-image-background-caroussel{
    width: 60vw;
}
div.display-foto-animal span.grow-height{
    display: block;
    padding-top: calc( 0.75 * 100%);
}
div.display-foto-animal .slider-wrapper{
    width: 60vw;
    max-width: 250px;
}

.toast-infowindow{
  max-width: 180px;
  background: var(--color-principal-complementar);
  color: white;
  font-weight: 700;
  top: 45vh;
  right: 0;
  position: absolute;
}

.toast-infowindow button{
  color: white;
  font-weight: 700;
}
