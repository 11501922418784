.menu-action-map-maker .my-dialog-card{
    background: var(--color-principal);
    border-radius: 6px;
}

.menu-action-map-maker .my-dialog-card button{
    border: none;
    background: none;
}

.menu-action-map-maker a{
    text-align: center;
    color: white;
}

.menu-action-map-maker *{
    color: white;
}

.menu-action-map-maker .fa-dog, .menu-action-map-maker .fa-map{
    font-size: 50px;
}
.menu-action-map-maker .fa-tags{
    position: relative;
    left: -6px;
    top: -4px;
}
.menu-action-map-maker .fa-draw-polygon{
    position: relative;
    left: -38px;
    top: -10px;
    color: white;
    color: var(--color-principal);
    font-size: 23px;
}

.my-float-btn{
    position: absolute;
    top:85px;
    z-index: 500;
    display: flex;
    width: 100%;
}
.my-float-btn span{
    margin: 0 auto;
    display: inline-block;
    color: var(--color-principal);
    border: 2px solid var(--color-principal);
    padding: 5px;
    border-radius: 5px;
    background: #ffffffb0;
    font-weight: bold;
}

