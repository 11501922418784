
/*inputs*/
.field-select{
    margin: 30px 0 0 0;
    width: 100%;
    color: var(--color-principal-complementar);
    font-size: 16px;
}
.field-select select {
    color: inherit;
    border:none;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
    border-bottom: solid 1px var(--color-principal-complementar);  
    height: 20px;       
}

option{
    font-size: 12px !important;
}