div.modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #1414142a;
    /* opacity: 0.15; */
    z-index: 100;
    display: flex;
    justify-content: center;
    color: #736A68;
}
div.modal a{
    color: inherit;
}
div.modal > div{
    background-color: #fafafa;
    border-radius: 7px;
    width: 90vw;
    margin-top: 7vh;
    margin-bottom: 10vh;
    /* opacity: 1; */
}
div.modal .modal-header{
    font-size: 18px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 1px solid #C9BDBB;
    margin: 0 25px 15px;
}
div.modal .modal-content{
    padding-left: 25px;
    padding-right:25px; 
}