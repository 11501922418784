div.my-dialog{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.my-dialog-card{
    position: relative;
    background: #fff;
    max-width: 300px;
    -webkit-box-shadow: 0px 0px 56px 1px #4d4d4d26;
    -moz-box-shadow: 0px 0px 56px 1px #4d4d4d26;
    box-shadow: 0px 0px 56px 1px #4d4d4d26;
}

div.my-dialog-header{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
div.my-dialog-content{
    margin: 10px 20px;
    font-size: 1.2rem
}
div.my-dialog-content > *{
    margin: 7px;
}