div.camera-container > section{
    max-width: 280px;
    margin: 0 auto;
}

div.h-100.card-image-add{
    padding: 10px 0;
}
p.legend{
    top: 0 !important;
    bottom: unset !important;
    display: flex !important;
    justify-content: space-between !important;
    border-radius: 0 !important;
    left: unset !important;
    margin-left: unset !important;
    width: 100% !important;
    margin-top: 0 !important;
    opacity: 0.15 !important;
    z-index: 200;
}
.carousel.carousel-slider .control-arrow[type="button"] {
    top: 35px;
}
.banner-inicial{
    margin-top: 2vh;
    min-height: 25vh;
    background: white;
    border-radius: 6px;
    border: solid 2px var(--color-principal);
    position: relative;
    color: var(--color-principal);
    text-align: center;
}
.banner-inicial .fa-plus{
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 16px;
}
.banner-inicial .fa-trash-alt{
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 16px;
}
.banner-inicial .fa-camera{
    position: absolute;
    bottom: 10vh;
    left: 0;
    width: 100%;
    font-size: 32px;
}
.banner-inicial .message{
    width: 100%;
    position: absolute;
    bottom: 2vh;
    left: 0;
}
.carousel .slide {
    background: none;
}