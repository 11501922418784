div.group-input-name{
    position: relative;
    z-index: 45;
}
div.group-input-name .btn-rand{
    color: var(--color-main-text-gray);
    position: absolute;
    bottom: 12px;
    right: 5px;
    z-index: 100;
}

div.coroussel-cadastro-animal .container-image-background-caroussel{
    width: 70vw;
}
div.coroussel-cadastro-animal span.grow-height{
    display: block;
    padding-top: calc( 0.75 * 100%);
}
div.coroussel-cadastro-animal .slider-wrapper{
    width: 70vw;
    max-width: 250px;
}

div.pag-cadastro .new-modal > div {
    max-height: 70vh;
    top: 12vh;
    margin: 0;
}
