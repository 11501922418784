:root{
  --color-principal: #FF4A3A;
  --color-principal-complementar: #005999;
  --color-main-text-gray: #736A68;
  --text-color-title-group: #005999;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

input,select{
  font-family: 'Roboto', sans-serif;
}

ul{
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-condensed{
  font-family: Roboto Condensed;
}
.flex-center-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.mt-30{
  margin-top: 30px;
}
.mt-60{
  margin-top: 60px;
}
.m-10{
  margin: 10px 0;
}
a:focus, button:focus, input:focus, select:focus{
  outline: 1px solid #00599942;
}

.soft-shadow{
  -webkit-box-shadow: 0px 0px 25px 1px #4d4d4d8c;
  -moz-box-shadow: 0px 0px 25px 1px #4d4d4d8c;
  box-shadow: 0px 0px 25px 1px #4d4d4d8c;
}

.loading-container{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: #ffffffb0;
  z-index: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-principal-complementar);
  font-size: 25px;
}

.table-historic{
  width: 100%;
  margin: 10px 0;
  font-size: 12px;
  color: var(--color-main-text-gray);
  font-weight: 700;
}

.table-historic li{
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  align-items: baseline;
}


