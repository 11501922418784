header.my-header{
    position: absolute;
    z-index: 100;
    top: 0;
}
header.my-header::after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 22%, rgba(255,255,255,0) 110%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 22%, rgba(255,255,255,0) 110%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 22%, rgba(255,255,255,0) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
header.my-header > nav{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

nav > div{
    align-self: flex-end;
}

div.nav-menu, div.icon-return{
    color: var(--color-principal-complementar);
    font-size: 30px;
    margin-right: 20px;
    margin-left: 20px;
}

.my-header a{
    color: inherit;
}  

.my-header nav{
    padding-bottom: 5px;
}  
  
.my-header .fa-sync-alt{
    opacity: 0.5;
}

div.nav-logo{
    padding-top: 5px;
}

.main-menu-app.my-dialog{
    background: #d3d3d373;
    top: 0;
    z-index: -1 ;
}

.main-menu-app .my-dialog-card{
    min-height: 60%;
    width: 90%;
    border-radius: 8px;
}

.main-menu-app.my-dialog a{
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #80808080;
}

.main-menu-app .my-dialog-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var( --color-main-text-gray);
    font-weight: bold;
}
