.toast-infowindow{
  max-width: 180px;
  background: var(--color-principal-complementar);
  color: white;
  font-weight: 700;
  top: 45vh;
  right: 0;
  position: absolute;
}

.toast-infowindow button{
  color: white;
  font-weight: 700;
}