ul.menu-modal{
    text-align: center;
    list-style: none;
    padding: 0;
    font-size: 20px;
}
ul.menu-modal li {
    margin: 15px;
}

ul.menu-modal a {
    text-decoration: none;
}