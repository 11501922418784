/*inputs*/
.field-input{
    margin: 10px 0 0 0;
    width: 100%;
    color: var(--color-principal-complementar);
    font-size: 16px;
}
.field-input label{
    color: inherit;
    position: relative;
    background-color: rgba(0,0,0,0.0);
    top: 15px;
    /* transition: transform 2000ms; */
    opacity: 1;
    display: block;
}
.field-input label.ativo{
    opacity: 0;
    transition: opacity 500ms;
}
.field-input input,.field-input textarea {
    color: inherit;
    border:none;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
    border-bottom: solid 1px var(--color-principal-complementar);  
    height: 25px;       
}
