.field-input.input-check{
    display: flex;
    justify-content: space-between;
}
.field-input.input-check label{
    top: unset;
    width: initial;
}
.field-input.input-check input{
    width: 25px;
    height: 25px;
}