div.new-modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #14141450;
    /* opacity: 0.15; */
    z-index: 999;
    display: flex;
    justify-content: center;
    color: #736A68;
    left: 0;
    top: 0;
    margin: 0 !important;
}
div.new-modal a{
    color: inherit;
}
div.new-modal > div{
    background-color: #fafafa;
    border-radius: 7px;
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    /* opacity: 1; */
    position: fixed;
}
div.new-modal .modal-header{
    font-size: 18px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 1px solid #C9BDBB;
    margin: 0 25px 15px;
}
div.new-modal .modal-content{
    padding-left: 25px;
    padding-right:25px; 
}