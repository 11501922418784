.historic-dialog.my-dialog{
  background: #d3d3d373;
  top: 0;
}

.historic-dialog .my-dialog-card{
  min-height: 60%;
  width: 90%;
  border-radius: 8px;
}

.historic-dialog .my-dialog-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var( --color-main-text-gray);
  font-weight: bold;
}

.my-dialog-content .table-historic li a{
  padding: 0;
}
