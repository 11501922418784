.no-align-item-center > div.my-container {
    align-items: initial !important;
    justify-content: center;
}

.my-container .sheet-description{
    width: 23vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

div.display-foto-animal{
    margin: 0 !important;
}

div.display-foto-animal .container-image-background-caroussel{
    width: 60vw;
}
div.display-foto-animal span.grow-height{
    display: block;
    padding-top: calc( 0.75 * 100%);
}
div.display-foto-animal .slider-wrapper{
    width: 60vw;
    max-width: 250px;
}
